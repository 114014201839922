<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="内容:">
                    <el-input class="s-input" v-model="queryParams.content" placeholder="请输入内容" clearable></el-input>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list" class="el-table">
                <el-table-column label="内容" prop="content" align="center"></el-table-column>
                <el-table-column label="平台" prop="platform_name" align="center" width="120"></el-table-column>
                <el-table-column label="开始时间" prop="ctime" width="160" align="center"></el-table-column>
                <el-table-column prop="up_time" label="结束时间" width="160" align="center"></el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            menuList: ['设置', '日志管理'],
            loading: false,
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            pageInfo: {},
            addVisible: false,
            title: '添加热词',
            form: {
                name: '',
                state: null,
                time: null
            },
            positionList: [],
            platformList: [],
        };
    },
    created() {
        deal.getconf('', this)
        this.loadData();
    },
    methods: {
        cellstyle() {
            return 'text-align: center';
        },
        //获取日志列表
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/system/log/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list
                    this.pageInfo.list.map(function (val) {
                        // val.ctime = deal.timestamp(val.ctime, 'unix');
                        // val.up_time = deal.timestamp(val.up_time, 'unix');
                        if (_this.platformList) {
                            _this.platformList.map((item) => {
                                if (item.key === val.module) {
                                    _this.$set(val, 'platform_name', item.name)
                                }
                            })
                        }
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        }
    }
};
</script>

<style scoped>
.el-table th > .cell {
    text-align: center;
}

.el-table .cell {
    text-align: center;
}

.search {
    padding-bottom: -20px;
}

.el-table {
    margin-top: 20px;
}
</style>